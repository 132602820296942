import React from "react"
import styled from "styled-components"

import Row from "../../../styles/Row"

import StaticCupSplash from "./StaticCupSplash"
import Finder from "./Finder"
import ThemeSwitcher from "./ThemeSwitcher"

const HeroSection = styled.section`
  position: relative;
`

const FlexRow = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36rem;

  @media (max-width: 900px) {
    height: 28rem;
  }
`

export default function Hero(props) {
  return (
    <HeroSection>
      <StaticCupSplash />
      <ThemeSwitcher />
      <FlexRow>
        <Finder />
      </FlexRow>
    </HeroSection>
  )
}
